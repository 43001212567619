<template>
  <div>
    <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip" placement="right">
      <el-button @click="action" class="fab-btn">
        +
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: ['action', 'tooltip']
}
</script>

<style scoped>
  .fab-btn {
    position: absolute;
    bottom: 5px;
    right: 12px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    /* transition: box-shadow 0.4s ease; */
    background: #EB2C3E;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6); */
    color: #fff;
    font-size: 1.7rem;
    font-weight: bold;
    cursor: pointer;
    z-index: 100;
  }

  .fab-btn span {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease;
  }

  .rotate {
    transform: rotate(315deg);
  }

  /* input:checked ~ .card-content {
    opacity: 1;
  } */
</style>
