<template>
  <div class="app-container">
    <el-dialog title="Add Evaluation" :visible.sync="addDialog" width="60%" :show-close="false" :close-on-click-modal="false">
      <el-form :model="addEvalForm" label-width="115px" :rules="rulesAddEval" ref="addEvalForm" :label-position="labelPos" size="mini" :validate-on-rule-change="false">
        <h3>Evaluation Details</h3>
        <el-row :gutter="24" style="padding: 0px 30px 0px 30px;">
          <el-col :md="12" :lg="12" :xs="24" :sm="24" >
            <el-form-item label="Company:" prop="selectedCompany">
              <el-select style="width: 100%;" v-model="addEvalForm.selectedCompany" filterable>
                <el-option v-for="company in companies" :key="company.id" :value="company.id" :label="company.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="12" :lg="12" :xs="24" :sm="24" >
            <el-form-item label="Name:" prop="evalName">
              <el-input autocomplete="off" v-model="addEvalForm.evalName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" style="padding: 0px 30px 0px 30px;">
          <el-col :md="12" :lg="12" :xs="24" :sm="24" >
            <el-form-item label="Evaluation Type:" prop="selectedEvalType">
              <el-select style="width: 100%;" v-model="addEvalForm.selectedEvalType" filterable>
                <el-option v-for="type in evaluationTypes" :key="type.id" :value="type.id" :label="type.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="12" :lg="12" :xs="24" :sm="24" >
            <el-form-item label="Employment Status:" prop="selectedEmploymentStatus">
              <el-select style="width: 100%;" v-model="addEvalForm.selectedEmploymentStatus" filterable>
                <el-option value="0" label="All">All</el-option>
                <el-option v-for="status in employmentStatuses" :key="status.id" :value="status.id" :label="status.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" style="padding: 0px 30px 0px 30px;">
          <el-col :md="12" :lg="12" :xs="24" :sm="24" >
            <el-form-item label="BSC:" prop="selectedBSC">
              <el-select style="width: 100%;" v-model="addEvalForm.selectedBSC" filterable>
                <el-option v-for="bsc in bscList" :key="bsc.id" :value="bsc.id" :label="bsc.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" style="padding: 0px 30px 0px 30px;">
          <!-- <el-col :span="12">
            <el-form-item label="Year:" prop="evalYear">
              <el-date-picker
                v-model="addEvalForm.evalYear"
                style="width: 100%;"
                type="year"
                format="yyyy"
                value-format="yyyy"
                placeholder="Pick a year">
              </el-date-picker>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :md="12" :lg="12" :xs="24" :sm="24" >
            <el-form-item label="Evaluation Date:" prop="evalDate">
              <el-date-picker
                style="width: 100%;"
                v-model="addEvalForm.evalDate"
                type="date"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                >
              </el-date-picker>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row :gutter="24" style="padding: 0px 30px 0px 30px;">
          <el-col :md="12" :lg="12" :xs="24" :sm="24">
            <el-checkbox :true-label=1 :false-label=0 v-model="noDate" style="padding-bottom: 10px;" @change="handleCheck($event)">Has No Date Covered</el-checkbox>
            <el-form-item label="Date Covered" prop="evalDateCovered">
              <el-date-picker
                :disabled="noDate"
                style="width:100%;"
                size="mini"
                v-model="addEvalForm.evalDateCovered"
                type="daterange"
                range-separator="-"
                start-placeholder="Start date"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                end-placeholder="End date">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="Employment Period Covered" prop="evalEmploymentCovered" class="el-form-item--label-top">
              <el-date-picker
                size="mini"
                style="width: 100%;"
                v-model="addEvalForm.evalEmploymentCovered"
                type="monthrange"
                range-separator="To"
                start-placeholder="Start date"
                value-format="M-yyyy"
                format="MMMM"
                end-placeholder="End date">
              </el-date-picker>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddEval">Cancel</el-button>
        <el-button style="background:#EB2C3E; color:white;" @click="submitAddEvalForm">Confirm</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Add Department" :visible.sync="addDeptDialog" width="40%" :show-close="false" :close-on-click-modal="false">
      <el-form :model="addDeptForm" ref="addDeptForm" :rules="rulesAddDept" size="medium" label-position="left">
        <el-row :gutter="24">
          <el-col :md="24" :lg="24" :xs="24" :sm="24">
            <el-form-item label="Department:" prop="selectedDept" style="padding: 0px 10px 0px 10px;">
              <el-select multiple style="width:80%;" v-model="addDeptForm.selectedDept" placeholder="Department" filterable>
                <el-option :disabled="addDeptForm.selectedDept.length > 0" :value="0" label="All">All</el-option>
                <el-option :disabled="addDeptForm.selectedDept.includes(0)" v-for="dept in deptsSelection" :key="dept.id" :value="dept.id" :label="dept.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddDept" size="mini">Cancel</el-button>
        <el-button style="background:#EB2C3E; color:white;" @click="submitAddDeptForm" size="mini">Add</el-button>
      </span>
    </el-dialog>

    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0;">EVALUATION DASHBOARD</h3>
        <div v-if="isViewDept">
          <el-select v-model="selectedSection" placeholder="Select Section" size="mini" filterable @change="selectedRank = ''">
            <el-option v-for="section in sections" :key="section.id" :value="section.id" :label="section.name"></el-option>
          </el-select>
          <el-select v-model="selectedRank" @change="viewMPPBSC()" placeholder="Select Rank" size="mini" filterable style="margin-left: 5px;" :disabled="selectedSection === ''">
            <el-option v-for="rank in ranks" :key="rank.id" :value="rank.id" :label="rank.name"></el-option>
          </el-select>
        </div>
      </div>
      <div v-if="!isViewDept">
        <el-row :gutter="24">
          <el-col :span="5" style="float: right;">
            <el-input
              v-model="deptSearchText"
              size="mini"
              @click="hello(scope.row)"
              placeholder="Search for Evaluation"
            />
          </el-col>
        </el-row>
        <el-table
          :data="mppTable.filter(data => !deptSearchText || data.name.toLowerCase().includes(deptSearchText.toLowerCase()))"
          >
          <el-table-column
            prop="name"
            label="Reviews"
            >
            <template slot-scope="scope">
              <span @click="viewDepts(scope.row)" style="cursor:pointer;">{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="evaluation_bg4_id_name"
            label="Review Period"
            >
          </el-table-column>
          <el-table-column
            prop="evaluation_date"
            label="Date Started"
            >
            <template slot-scope="scope">
              {{formatDate(scope.row.created_at)}}
            </template>
          </el-table-column>
          <el-table-column
            prop="date_completed"
            label="Date Completed"
            >
          </el-table-column>
          <el-table-column
            prop="a_id_name"
            label="Status"
            >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="Action"
            align="right"
            width="300"
            >
            <template slot-scope="scope">
              <el-button style="background:#EB2C3E; color:white;" @click="getDepts(scope.row)" size="mini">Add Department</el-button>
              <el-button v-if="scope.row.a_id == '25'" style="background:#EB2C3E; color:white;" @click="sendForReview(scope.row)" size="mini">Complete</el-button>
              <el-button v-else-if="scope.row.a_id == '23'" style="background:#EB2C3E; color:white;" @click="sendForReview(scope.row)" size="mini">Send For Review</el-button>
            </template>
          </el-table-column>
        </el-table>
        <h3>DEPARTMENTS <span v-if="selectedEval !== ''">-</span> {{selectedEval.name}}</h3>
        <el-row :gutter="24">
          <el-col :span="5" style="float: right;">
            <el-input
              v-model="evalSearchText"
              size="mini"
              @click="hello(scope.row)"
              placeholder="Search for Department"
            />
          </el-col>
        </el-row>
        <el-table
          :data="evalDepts.filter(data => !evalSearchText || data.bd_id_name.toLowerCase().includes(evalSearchText.toLowerCase()))"
          style="width: 100%">
          <el-table-column
            prop="bd_id_name"
            label="Department"
            >
          </el-table-column>
          <el-table-column
            prop="date_started"
            label="Date Started"
            >
          </el-table-column>
          <el-table-column
            prop="date_completed"
            label="Date Completed"
            >
          </el-table-column>
          <el-table-column
            prop="a_id_name"
            label="Status"
            >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="Action"
            align="right"
            >
            <template slot-scope="scope">
              <el-button style="background:#EB2C3E; color:white;" @click="viewDept(scope.row)" size="mini">View Department</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <el-row><el-button @click="isViewDept = false; tableData = []; selectedSection = ''; selectedRank = '';" size="mini"><i class="el-icon-back"></i></el-button></el-row>
        <h1>{{selectedDept.bd_id_name}}</h1>
        <el-collapse v-for="(parentItem, parentIndex) in tableData" :key="parentIndex">
          <el-collapse-item :title="parentItem.category_bg4_id_name + ' - ' + parentItem.weight + '%'" :name="parentItem.category_bg4_id_name">
            <div v-for="(childItem, childIndex) in parentItem.perspective" :key="childIndex">
              <h1 style="font-size:13px !important;">{{childItem.perspective_bg4_id_name}}</h1>
                <div v-for="(grandChildItem, grandChildIndex) in childItem.strategic_objectives" :key="grandChildIndex">
                  <h2>{{grandChildItem.objective_bg4_id_name}}</h2>
                  <el-table
                    :data="grandChildItem.attributes"
                    style="width: 100%">
                    <el-table-column
                      prop="weight"
                      label="% Weight"
                      >
                    </el-table-column>
                    <el-table-column
                      prop="measurement_bg4_id_name"
                      label="Strategic Measurements (KPI)"
                      >
                    </el-table-column>
                    <el-table-column
                      prop="threshold"
                      label="Threshold"
                      >
                    </el-table-column>
                    <el-table-column
                      prop="target"
                      label="Target"
                      >
                    </el-table-column>
                    <el-table-column
                      prop="superior"
                      label="Superior"
                      >
                    </el-table-column>
                    <el-table-column
                      prop="actual"
                      label="Actual"
                      >
                    </el-table-column>
                    <!-- <el-table-column
                      prop="rating"
                      label="Rating"
                      >
                      <template slot-scope="scope">
                        <span v-if="!scope.row.isEdit">{{scope.row.employee_document_mpp_bsc.rating}}</span>
                        <el-input v-model="scope.row.employee_document_mpp_bsc.rating" v-else type="number"></el-input>
                      </template>
                    </el-table-column> -->
                    <!-- <el-table-column
                      fixed="right"
                      label="Action"
                      >
                      <template slot-scope="scope">
                        <el-tooltip content="Edit" placement="top" v-if="!scope.row.isEdit">
                          <el-button type="text" :disabled="scope.row.employee_document_mpp_bsc.a_id === '8'" @click="edit(parentIndex, childIndex, grandChildIndex, scope.$index, true)"><i class="el-icon-edit"></i></el-button>
                        </el-tooltip>
                        <el-tooltip content="Confirm Edit" placement="top" v-else>
                          <el-button type="text" @click="updateRating(scope.row)"><i class="el-icon-check"></i></el-button>
                        </el-tooltip>
                      </template>
                    </el-table-column> -->
                  </el-table>
                  <h3>Sub-Total: {{computeTotal(grandChildItem)}}</h3>
                </div>
              <hr>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-card>
    <fab v-if="!isViewDept" :action="showAddDialog" tooltip="Add Evaluation"/>
  </div>
</template>

<script>
import fab from '@/components/FAB/FAB.vue'
import { format, parseISO } from 'date-fns'
export default {
  components: {
    fab
  },
  created () {
    this.getBSC()
    this.getRanks()
    this.getEmploymentStatuses()
    this.getEvaluationTypes()
    this.getMPP()
    this.getCompanies()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    // this.getEvalDepts()
  },
  computed: {
    labelPos () {
      if (this.windowWidth < 992) {
        return 'top'
      } else {
        return 'left'
      }
    },
    rulesAddEval () {
      return {
        evalName: [
          { required: true, message: 'Please input evaluation name', trigger: 'blur' }
        ],
        selectedCompany: [
          { required: true, message: 'Please input evaluation company', trigger: 'change' }
        ],
        selectedBSC: [
          { required: true, message: 'Please select a BSC!', trigger: 'change' }
        ],
        // evalYear: [
        //   { required: true, message: 'Please pick a year', trigger: 'change' }
        // ],
        selectedEvalType: [
          { required: true, message: 'Please pick an evaluation type', trigger: 'change' }
        ],
        selectedEmploymentStatus: [
          { required: true, message: 'Please pick an employment status', trigger: 'change' }
        ],
        evalDate: [
          { required: true, message: 'Please select evaluation date date covered', trigger: 'change' }
        ],
        evalDateCovered: [
          { required: !this.noDate, message: 'Please select date period covered', trigger: 'change' }
        ]
        // evalEmploymentCovered: [
        //   { required: true, message: 'Please select employment period covered', trigger: 'change' }
        // ]
      }
    }
  },
  data () {
    return {
      tableData: [],
      selectedSection: '',
      deptTable: [],
      isViewDept: false,
      windowWidth: window.innerWidth,
      noDate: 0,
      selectedEval: '',
      selectedDept: '',
      deptSearchText: '',
      evalSearchText: '',
      addDeptForm: {
        selectedDept: []
      },
      addEvalForm: {
        evalName: '',
        selectedCompany: '',
        evalDate: '',
        // evalYear: null,
        selectedEvalType: '',
        selectedEmploymentStatus: '',
        evalDateCovered: [],
        selectedBSC: ''
        // evalEmploymentCovered: ''
      },
      rulesAddDept: {
        selectedDept: [
          { required: true, message: 'Please select a department!', trigger: 'blur' }
        ]
      },
      addDeptDialog: false,
      addDialog: false,
      mppTable: [],
      employmentStatuses: [],
      companies: [],
      evalDepts: [],
      bscList: [],
      evaluationTypes: [],
      deptsSelection: [],
      selectedHemeId: '',
      selectedBCId: '',
      ranks: [],
      selectedRank: '',
      sections: []
    }
  },
  methods: {
    computeTotal (item) {
      var total = 0
      item.attributes.forEach(e => {
        total += parseFloat(e.weight)
      })
      if (isNaN(total)) {
        return ''
      }
      return total.toFixed(1) + '%'
    },
    viewMPPBSC () {
      var formSubmit = {}
      formSubmit.bd_id = this.selectedDept.bd_id
      formSubmit.bms_id = this.selectedDept.bms_id
      formSubmit.bj_id = this.selectedRank
      formSubmit.bds_id = this.selectedSection
      this.$http
        .post('mpp-view-bsc', formSubmit)
        .then(res => {
          if (res.data.StatusCode) {
            this.tableData = res.data.body
          } else {
            this.$message({
              showClose: true,
              message: res.data.body,
              type: 'error'
            })
          }
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    handleChange () {
      console.log('hello')
    },
    getRanks () {
      this.$http
        .get('backend.global4-list/31')
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.ranks = []
          } else {
            this.ranks = res.data.body
          }
        })
    },
    getSections () {
      this.$http
        .get('backend.department.section-list/' + this.selectedDept.bd_id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.sections = []
          } else {
            this.sections = res.data.body
          }
        })
    },
    viewDept (item) {
      this.isViewDept = true
      this.selectedDept = item
      this.getSections()
    },
    sendForReview (item) {
      var formSubmit = {}
      formSubmit.id = item.id
      formSubmit.a_id = item.a_id === '25' ? 8 : 25
      this.$http
        .post('mpp.evaluation-update-draft', formSubmit)
        .then(res => {
          if (res.data.StatusCode) {
            this.$message({
              showClose: true,
              message: 'Form Submitted',
              type: 'success'
            })
            this.getMPP()
          } else {
            this.$message({
              showClose: true,
              message: res.data.body,
              type: 'error'
            })
          }
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    formatDate (date) {
      return format(parseISO(date), 'MM/dd/yyyy')
    },
    handleCheck (event) {
      if (event === 1) {
        this.addEvalForm.evalDateCovered = []
      }
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    hello (hello) {
      console.log(hello)
    },
    cancelAddEval () {
      this.addDialog = false
      this.noDate = 0
      this.$refs.addEvalForm.resetFields()
    },
    showAddDialog () {
      this.evalDepts = []
      this.addDialog = true
    },
    cancelAddDept () {
      this.addDeptForm.selectedDept = ''
      this.selectedHemeId = ''
      this.selectedBCId = ''
      this.addDeptDialog = false
    },
    getDepts (item) {
      console.log(item)
      this.selectedHemeId = item.id
      this.selectedBCId = item.bc_id
      this.$http
        .get('mpp.evaluation-get-department/' + this.selectedHemeId + '/' + this.selectedBCId)
        .then(res => {
          this.deptsSelection = res.data.body
          this.addDeptDialog = true
        })
    },
    viewDepts (item) {
      this.selectedEval = item
      this.$http
        .get('mpp.evaluation-per-department-list/' + item.id)
        .then(res => {
          if (res.data.StatusDescription === 'Sorry! Nothing was found.') {
            this.evalDepts = []
            this.selectedEval = ''
          } else {
            this.evalDepts = res.data.body
          }
        })
    },
    submitAddDeptForm () {
      this.$refs.addDeptForm.validate((valid) => {
        if (valid) {
          var formSubmit = {}
          formSubmit.bd_id = this.addDeptForm.selectedDept
          formSubmit.heme_id = this.selectedHemeId
          console.log(formSubmit)
          this.$http
            .post('mpp.evaluation-department-section-rank', formSubmit)
            .then(res => {
              if (res.data.StatusCode) {
                this.viewDepts(this.selectedHemeId)
                this.addDeptForm.selectedDept = ''
                this.selectedHemeId = ''
                this.selectedBCId = ''
                this.addDeptDialog = false
                this.$message({
                  showClose: true,
                  message: 'Form Submitted',
                  type: 'success'
                })
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.body,
                  type: 'error'
                })
              }
            })
            .catch(() => {
              this.$message({
                showClose: true,
                type: 'danger',
                message: 'Uh-oh! Something went wrong.'
              })
            })
        } else {
          alert('invalid form')
        }
      })
    },
    submitAddEvalForm () {
      this.$refs.addEvalForm.validate((valid) => {
        if (valid) {
          var formSubmit = {}
          formSubmit.name = this.addEvalForm.evalName
          formSubmit.evaluation_bg4_id = this.addEvalForm.selectedEvalType
          // formSubmit.evaluation_date = this.addEvalForm.evalDate
          formSubmit.date_from = this.addEvalForm.evalDateCovered[0]
          formSubmit.date_to = this.addEvalForm.evalDateCovered[1]
          // formSubmit.date_year = this.getYearDate(this.addEvalForm.evalDateCovered[0])
          formSubmit.bes_id = this.addEvalForm.selectedEmploymentStatus
          // formSubmit.bes_months_from = this.getMonthDate(this.addEvalForm.evalDateCovered[0])
          // formSubmit.bes_months_to = this.getMonthDate(this.addEvalForm.evalDateCovered[1])
          formSubmit.is_no_date_fromto = this.noDate
          formSubmit.bc_id = this.addEvalForm.selectedCompany
          formSubmit.a_id = 23
          formSubmit.bms_id = this.addEvalForm.selectedBSC
          this.$http
            .post('mpp.evaluation', formSubmit)
            .then(res => {
              // this.reset()
              if (res.data.StatusCode) {
                this.addDialog = false
                this.$refs.addEvalForm.resetFields()
                this.noDate = false
                this.getMPP()
                this.$message({
                  showClose: true,
                  message: 'Form Submitted',
                  type: 'success'
                })
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.body,
                  type: 'error'
                })
              }
            })
            .catch(() => {
              this.$message({
                showClose: true,
                type: 'danger',
                message: 'Uh-oh! Something went wrong.'
              })
            })
        } else {
          return false
        }
      })
    },
    getMonthDate (date) {
      var beforeFormat = new Date(date)
      var month = beforeFormat.getMonth() + 1
      return parseInt(month)
    },
    getYearDate (date) {
      var beforeFormat = new Date(date)
      var year = beforeFormat.getFullYear()
      return year
    },
    getBSC () {
      this.$http
        .get('backend.mpp-bsc-maintenance-list')
        .then(res => {
          console.log(res)
          this.bscList = res.data.body
        })
    },
    getCompanies () {
      this.$http
        .get('backend.company-list')
        .then(res => {
          console.log(res)
          this.companies = res.data.body
        })
    },
    getEvalDepts () {
      this.$http
        .get('mpp.evaluation-department-list')
        .then(res => {
          console.log(res)
          this.evalDepts = res.data.body
        })
    },
    getMPP () {
      this.$http
        .get('mpp.evaluation-list')
        .then(res => {
          console.log(res)
          this.mppTable = res.data.body
        })
    },
    getEvaluationTypes () {
      this.$http
        .get('backend.global4-list/95')
        .then(res => {
          this.evaluationTypes = res.data.body
        })
    },
    getEmploymentStatuses () {
      this.$http
        .get('backend.employment-status-list')
        .then(res => {
          this.employmentStatuses = res.data.body
        })
    }
  }
}
</script>
<style>
  /* .el-form-item--label-top .el-form-item__label {
    width: auto!important;
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 10px;
  }

  .el-form-item--label-top .el-form-item__content {
    margin-left: 0!important;
  } */
  /* .is-right{
    display: none;
  }
  .el-picker-panel__icon-btn {
    display: none;
  } */
</style>
